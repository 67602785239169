import React from 'react';
import HttpsRedirect from 'react-https-redirect';
import Styling from 'layouts/Styling/Styling';

export default function Layout({ children }) {
  return (
    <React.Fragment>
      <div id="top"></div>
      <Styling />
      <HttpsRedirect>{children}</HttpsRedirect>
    </React.Fragment>
  );
}
